@use "styles/sass/partials/newGlobalStyle";
@import "styles/sass/partials/variables";
@import "styles/sass/partials/mixins";

.modalRightBox {
  @include for-size(desktop) {
    width: 400px !important;
    border-radius: unset;
    max-height: 100vh;
    height: 100vh;
    & > div:first-child {
      margin: 0px;
    }
  }
  @include for-size(phone) {
    @include border-radius(0);
    width: 100% !important;
    padding-top: 55px;
    min-height: 60%;
    background-color: transparent;
    & > div:first-child {
      background: #fff;
      padding: 24px 24px 0px;
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
      margin-bottom: -2px;
    }
  }
}

.rightModal {
  justify-content: flex-end;
}

.title {
  justify-content: flex-start !important;

  h2 {
    font-size: 14px;
    line-height: 20px;
    color: $lightBlack;
    position: relative;
    top: -3px;
  }
}

.modalContent {
  padding: 0 !important;
  @include for-size(phone) {
    border: none;
    background: #fff;
  }
}

.cancelBtn {
  display: none;
  border: none;
  background: transparent;
  &:hover,
  &:focus {
    background: transparent;
    @include box-shadow(none);
  }

  @include for-size(phone) {
    position: absolute;
    top: 0;
    @include transform(translateX(-50%));
    left: 50%;
    width: 40px;
    height: 40px;
    background: #4b4a48;
    margin: 0 !important;
    padding: 0 !important;
    min-width: 40px;
    @include border-radius(50%);
    @include flex(center, center, row);
    &:hover,
    &:focus {
      background: #4b4a48;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 2px;
      background: #fff;
      transform-origin: center;
      border-radius: 14px;
      transform: translate(-50%, -50%);
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
  span {
    @include for-size(phone) {
      display: none;
    }
  }
}

.modalFooter {
  display: none;
  flex-direction: column;
  @include for-size(phone) {
    display: contents;
  }
}

.loginForm {
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  min-height: 200px;
  @include for-size(phone) {
    border-radius: 0px;
    padding: 20px 20px 30px;
    min-height: 350px;
  }
}

.loginModal {
  z-index: 1000;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .modalRightBox {
    width: 100% !important;
  }
}
.hideCloseIcon {
  display: none;
}
