@import "../../../styles//style.module.scss";

.Navigation {
  position: fixed;
  width: 100%;
  z-index: 99;
  bottom: 0;
  min-height: 66px;
  @include box-shadow(0 -4px 8px rgba(0, 0, 0, 0.08));
  align-items: flex-end;

  ul {
    @include flex(center, space-between, row);
    background: $white;
    height: 66px;
    box-sizing: border-box;
    padding: 9px 16px;
    gap: 2px;

    li {
      a {
        @include flex(center, unset, column);
        font-size: 10px;
        line-height: 16px;
        font-weight: 400;
        color: $grey10;
        text-decoration: none;
        gap: 6px;

        span {
          height: 20px;
          width: 20px;
          text-align: center;
          @include border-radius(8px);
          background: transparent;
          font-size: 20px;
        }

        &:hover,
        &.active {
          color: $brandColor6;
          font-weight: 600;
        }
      }
    }
  }
}
